
import { computed, defineComponent, onMounted } from "vue";
import { Field, ErrorMessage } from "vee-validate";
import { useStore } from "vuex";

export default defineComponent({
  name: "ExpertStep3",
  components: {
    Field,
    ErrorMessage,
  },
  setup() {
    const store = useStore();
    const allIndustries = computed(
      () => store.getters["UserModule/getAllIndustries"]
    );
    const seniorityLevels = computed(
      () => store.getters["ExpertModule/getSeniorityLevels"]
    );

    onMounted(async () => {
      await store.dispatch("UserModule/fetchAllIndustries");
      await store.dispatch("ExpertModule/fetchSeniorityLevels");
    });

    return { allIndustries, seniorityLevels };
  },
});
