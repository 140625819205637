
import { computed, defineComponent, onMounted, ref } from "vue";
import Step1 from "@/user-journey/components/Step1.vue";
import Step5 from "@/user-journey/components/Step5.vue";
import ExpertStep1 from "@/user-journey/components/ExpertStep1.vue";
import ExpertStep2 from "@/user-journey/components/ExpertStep2.vue";
import ExpertStep3 from "@/user-journey/components/ExpertStep3.vue";
import { StepperComponent } from "@/assets/ts/components";
import * as Yup from "yup";
import { useForm } from "vee-validate";
import { getAuth } from "firebase/auth";
import { useRouter } from "vue-router";
import { createExpert } from "@/api/expert.api";
import { getUser } from "@/api/user.api";
import { useStore } from "vuex";

interface IStep1 {
  accountType: string;
}

interface IStep2 {
  projectStatuses: Array<string>;
  industryVerticals: Array<string>;
}

interface IStep3 {
  profileUrl: string;
  city: string;
}

interface IStep4 {
  company: string;
  levelOfSeniority: string;
  activeIndustry: string;
}

interface ExpertWizard extends IStep1, IStep2, IStep3, IStep4 {}

export default defineComponent({
  name: "SignUpWizard",
  components: {
    Step1,
    Step5,
    ExpertStep1,
    ExpertStep2,
    ExpertStep3,
  },
  async beforeRouteEnter(to, from, next) {
    const uuid = getAuth().currentUser?.uid;
    try {
      await getUser(uuid);
      await next("/dashboard");
    } catch (e) {
      next();
    }
  },
  setup() {
    const store = useStore();
    const _stepperObj = ref<StepperComponent | null>(null);
    const verticalWizardRef = ref<HTMLElement | null>(null);
    const currentStepIndex = ref(1);
    const submitButtonRef = ref<HTMLButtonElement | null>(null);

    const formData = ref<ExpertWizard>({
      accountType: "expert",
      projectStatuses: [],
      industryVerticals: [],
      profileUrl: "",
      city: "",
      company: "",
      levelOfSeniority: "",
      activeIndustry: "",
    });
    const wizardType = ref("expert");
    const router = useRouter();

    onMounted(() => {
      _stepperObj.value = StepperComponent.createInsance(
        verticalWizardRef.value as HTMLElement
      );
      if (!_stepperObj.value) {
        return;
      }
      _stepperObj.value.goNext();
    });

    const createAccountSchema = [
      Yup.object({
        accountType: Yup.string().required().label("Account Type"),
      }),
      Yup.object({
        projectStatuses: Yup.array()
          .required()
          .label("Project Status")
          .min(1, "Select minimum 1 value"),
        industryVerticals: Yup.array()
          .required()
          .label("Industry Verticals")
          .min(1, "Select minimum 1 value"),
      }),
      Yup.object({
        profileUrl: Yup.string().matches(
          /^$|(http(s)?:\/\/)?([\w]+\.)?linkedin\.com\/(pub|in|profile)|(http(s)?:\/\/)?([\w]+\.)?xing\.com\/profile\//,
          "Enter correct url!"
        ),
        // city: Yup.string().required().label("City"),
      }),
      Yup.object({
        // company: Yup.string().required().label("Company"),
        // levelOfSeniority: Yup.string().required().label("Level Of Seniority"),
        // activeIndustry: Yup.string().required().label("Active Industry"),
      }),
    ];

    const currentSchema = computed(() => {
      return createAccountSchema[currentStepIndex.value];
    });

    const { resetForm, handleSubmit } = useForm<IStep1 | IStep2 | IStep3>({
      validationSchema: currentSchema,
    });

    const totalSteps = computed(() => {
      if (!_stepperObj.value) {
        return;
      }

      return _stepperObj.value.totatStepsNumber;
    });

    resetForm({
      values: {
        ...formData.value,
      },
    });

    const handleStep = handleSubmit((values) => {
      formData.value = {
        ...formData.value,
        ...values,
      };

      currentStepIndex.value++;

      if (!_stepperObj.value) {
        return;
      }

      _stepperObj.value.goNext();
    });

    const previousStep = () => {
      if (!_stepperObj.value) {
        return;
      }

      currentStepIndex.value--;

      _stepperObj.value.goPrev();
    };

    const formSubmit = async () => {
      const payload = {
        uuid: getAuth().currentUser?.uid,
        role: 2,
        projectStatuses: formData.value.projectStatuses,
        preferredIndustry: formData.value.industryVerticals,
        profileLink: formData.value.profileUrl,
        city: formData.value.city,
        companyName: formData.value.company,
        levelOfSeniority: formData.value.levelOfSeniority,
        activeIndustry: formData.value.activeIndustry,
      };
      try {
        if (submitButtonRef.value) {
          //Disable button
          submitButtonRef.value.disabled = true;
          // Activate indicator
          submitButtonRef.value.setAttribute("data-kt-indicator", "on");
        }
        const { data } = await createExpert(payload);
        await store.dispatch("UserModule/fetchUserAndSave");
        await store.dispatch("UserModule/setUserId", data.data.user.id);
        await router.push("/profile");
      } catch (e) {
        console.error(e);
      } finally {
        if (submitButtonRef.value) {
          submitButtonRef.value.disabled = true;
          // Activate indicator
          submitButtonRef.value.setAttribute("data-kt-indicator", "on");
        }
      }
    };

    return {
      verticalWizardRef,
      previousStep,
      handleStep,
      formSubmit,
      totalSteps,
      currentStepIndex,
      wizardType,
      submitButtonRef,
    };
  },
});
