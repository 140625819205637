
import { computed, defineComponent, onMounted } from "vue";
import { Field, ErrorMessage } from "vee-validate";
import Multiselect from "@vueform/multiselect";
import { useStore } from "vuex";

export default defineComponent({
  name: "ExpertStep1",
  components: {
    Field,
    ErrorMessage,
    Multiselect,
  },
  setup() {
    const store = useStore();
    const projectStatuses = computed(
      () => store.getters["UserModule/getAllProjectStatuses"]
    );
    const allIndustries = computed(
      () => store.getters["UserModule/getAllIndustries"]
    );

    onMounted(async () => {
      await store.dispatch("UserModule/fetchAllProjectStatuses");
      await store.dispatch("UserModule/fetchAllIndustries");
    });

    return { allIndustries, projectStatuses };
  },
});
